import type { IStorageHelperOptions } from 'o365.modules.StorageHelpers.ts';
import { reactive, computed, } from 'vue'
import storageHelper from 'o365.modules.StorageHelpers.ts';
import logger from 'o365.modules.Logger.ts';

const localState = reactive({});

export default function useLocalStorageRef<T>(pKey: string, pInitialValue?: T, pOptions?: IStorageHelperOptions): { value?: T} {
    const key = storageHelper.getStorageKey(pKey, pOptions);
    try {
        const item = JSON.parse(storageHelper.getItem(pKey, pOptions));
        if (item) {
            localState[key] = item;
        }
    } catch (ex) {
        localState[key] = pInitialValue;
    }

    function saveToLocalStorage(pValue: T) {
        try {
            storageHelper.setItem(pKey, JSON.stringify(pValue), pOptions);
        } catch(ex) {
            logger.error(ex);
        }
    }

    return computed({
        get() { return localState[key] },
        set(pValue: T) { localState[key] = pValue; window.requestAnimationFrame(() => saveToLocalStorage(pValue)); }
    });
}